import Image from "next/future/image"
import Link from "next/link"
import format from 'date-fns/format'
import { getPathForBlogPost } from "utils/routing"
import { useState } from 'react'

function BlogCard({ visible, blog }) {
    const thumbnailImage = blog.thumbnailImage?.data?.attributes || {};

    return (
        <div className={`${visible ? 'block' : 'hidden'}`}>
            <Link href={getPathForBlogPost(blog.category?.data?.attributes?.slug, blog.slug)} passHref>
                <a className="group rounded-lg shadow-lg border flex flex-col justify-between">
                    {thumbnailImage.url && (
                        <div className="aspect-h-3 aspect-w-4 rounded-t-lg">
                           <Image
  src={thumbnailImage.url}
  width={thumbnailImage.width}
  height={thumbnailImage.height}
  className="h-full w-full object-cover object-center"
  alt={thumbnailImage.alternativeText}
  unoptimized
/>
                        </div>
                    )}
                    <div className="px-4 py-2">
                        <header className="text-3xl text-pink-500 group-hover:text-pink-900">{blog.title}</header>
                        <span className="text-gray-600">{format(blog.publishedAt ? Date.parse(blog.publishedAt) : new Date(), 'MMMM do, yyyy')}</span>
                    </div>
                </a>
            </Link>
        </div>
    )
}

export default function BlogList({ blogs, pageSize = 9 }) {
    const [size, setSize] = useState(pageSize);

    return (
        <div className="flex flex-col gap-6 mb-6">
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                {blogs.map((blog, idx) => (
                    <BlogCard visible={idx < size} key={blog.slug} blog={blog} />
                ))}
            </div>
            <div className="flex justify-center items-center">
                {size < blogs.length && (
                        <button className="bg-pink-900 text-white px-4 py-2 rounded text-lg" onClick={() => {
                            setSize(size + pageSize);
                        }}>See More...</button>
                )}
            </div>
        </div>
    );
}
